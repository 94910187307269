import {
  Box,
  Button,
  Flex,
  Icon,
  Link as ChakraLink,
  SkeletonCircle,
  SkeletonText,
  Stack,
  StackProps,
} from "@chakra-ui/react"
import {
  NavigationLinkEntityHydrated,
  PageEntityHydrated,
} from "@jackfruit/common"
import arrayToTree from "array-to-tree"
import { Link as GatsbyLink, navigate } from "gatsby"
import React, { useCallback, useMemo, useState } from "react"
import { BiCaretDown, BiCaretUp } from "react-icons/bi"
import { useAuth } from "~/hooks/useAuth"
import { useSettings } from "~/hooks/useSettings"
import { addPagePathToNavigationLink } from "~/services/Utils"
import MyAccountAccordion from "../header/MyAccount/MyAccountAccordion"
import SignInAccordion from "../header/SignIn/SignInAccordion"
import HtmlAddon from "./HtmlAddon"

interface NavigationButtonProps {
  color: string
  subLinks?: NavigationLinkEntityHydrated[]
  nestingLevel: number
  path: string
  isExternal: boolean
  children: React.ReactNode
}

const NavigationButton: React.FC<NavigationButtonProps> = ({
  color,
  subLinks,
  nestingLevel,
  children,
  path,
  isExternal,
  ...rest
}) => {
  const style = { color: color }
  const [showSub, setShowSub] = useState(false)

  const onButtonClick = useCallback(() => {
    if (subLinks) {
      setShowSub(toShow => !toShow)
      return
    }

    isExternal ? window.open(path, "newWindow") : navigate(path)
  }, [isExternal, path, subLinks])

  // Change nesting menu level size here
  const fontWeights = [700, 500, 500, 500]
  const fontSizes = ["md", "md", "sm", "xs"]

  return (
    <Stack
      direction="column"
      align="flex-start"
      width="100%"
      {...rest}
      px={2}
      spacing={3}
    >
      <Button
        {...rest}
        variant="link"
        borderRadius={0}
        fontSize={fontSizes[nestingLevel]}
        fontWeight={fontWeights[nestingLevel]}
        style={style}
        width="100%"
        onClick={onButtonClick}
      >
        <Stack direction="row" width="100%" justify="space-between">
          <Box>{children}</Box>
          {subLinks && (
            <Flex alignItems="center">
              {!showSub && <Icon as={BiCaretDown} />}
              {showSub && <Icon as={BiCaretUp} />}
            </Flex>
          )}
        </Stack>
      </Button>
      {showSub &&
        subLinks &&
        subLinks.map(link => (
          // Change submenu tab indent here
          <Box width="100%" px={1} key={link.id}>
            <NavigationLinkMobile
              color={color}
              link={link}
              nestingLevel={nestingLevel + 1}
            />
          </Box>
        ))}
    </Stack>
  )
}

interface NavigationLinkProps {
  color: string
  link: NavigationLinkEntityHydrated
  nestingLevel: number
}

const NavigationLinkMobile: React.FC<NavigationLinkProps> = ({
  link,
  color,
  nestingLevel,
}) => {
  const {
    type,
    path,
    label,
    labelPrefixHtml,
    labelSuffixHtml,
    external,
    children,
    page,
  } = link

  switch (type) {
    case "link":
      if (external) {
        return (
          <NavigationButton
            color={color}
            nestingLevel={nestingLevel}
            isExternal={true}
            path={path}
          >
            <HtmlAddon placement={"left"} html={labelPrefixHtml}></HtmlAddon>
            <ChakraLink href={path} rel="noopener" isExternal>
              {label}
            </ChakraLink>
            <HtmlAddon placement={"right"} html={labelSuffixHtml}></HtmlAddon>
          </NavigationButton>
        )
      } else {
        return (
          <NavigationButton
            color={color}
            nestingLevel={nestingLevel}
            isExternal={false}
            path={path}
          >
            <HtmlAddon placement={"left"} html={labelPrefixHtml}></HtmlAddon>
            <GatsbyLink to={path}>{label}</GatsbyLink>
            <HtmlAddon placement={"right"} html={labelSuffixHtml}></HtmlAddon>
          </NavigationButton>
        )
      }
    case "page":
      return (
        <NavigationButton
          color={color}
          nestingLevel={nestingLevel}
          isExternal={false}
          path={`/${page.path}`}
        >
          <HtmlAddon placement={"left"} html={labelPrefixHtml}></HtmlAddon>
          <GatsbyLink to={`/${page.path}`}>{label}</GatsbyLink>
          <HtmlAddon placement={"right"} html={labelSuffixHtml}></HtmlAddon>
        </NavigationButton>
      )
    case "text":
      return (
        <NavigationButton
          color={color}
          subLinks={children}
          nestingLevel={nestingLevel}
          isExternal={false}
          path={""}
        >
          <HtmlAddon placement={"left"} html={labelPrefixHtml}></HtmlAddon>
          {label}
          <HtmlAddon placement={"right"} html={labelSuffixHtml}></HtmlAddon>
        </NavigationButton>
      )
    case "megamenu":
      return null
  }
}

interface Props extends StackProps {
  color: string
  navigation: { links: NavigationLinkEntityHydrated[] }
  pages: PageEntityHydrated[]
}

const NavigationMobile: React.FC<Props> = ({
  color,
  navigation,
  pages,
  ...rest
}) => {
  const { state: authState } = useAuth()

  const {
    settings: { isUserAccountEnabled },
  } = useSettings()

  const navigationTree = useMemo(() => {
    const orderedLinks = addPagePathToNavigationLink(navigation.links, pages)
    const tree = arrayToTree(orderedLinks, {
      parentProperty: "parentId",
    })
    return tree
  }, [navigation.links, pages])

  return (
    <Stack
      direction="column"
      align="flex-start"
      {...rest}
      px={0}
      spacing={3}
      color={color}
    >
      {isUserAccountEnabled && (
        <>
          {authState.isLoading ? (
            <Flex alignItems="center" position="relative" width="100%" ml={2}>
              <SkeletonCircle size="5" />
              <SkeletonText noOfLines={1} width="50%" ml={2} />
            </Flex>
          ) : (
            <>
              {!authState.isAuthenticated ? (
                <SignInAccordion />
              ) : (
                <MyAccountAccordion />
              )}
            </>
          )}
        </>
      )}

      {navigationTree.map(link => (
        <NavigationLinkMobile
          color={color}
          key={link.id}
          link={link}
          nestingLevel={0}
        />
      ))}
    </Stack>
  )
}

export default NavigationMobile
