import {
  BlockEntityHydrated,
  BlockTemplateEntityHydrated,
  ClientEntityHydrated,
  ClientPrintServiceEntityHydrated,
  PageEntityHydrated,
  PrinticularCategoryEntity,
  PrinticularProductTemplateCategoryEntity,
  PrinticularProductTemplateEntity,
  PrinticularProductTemplateTagEntity,
  PrintServiceEntity,
  PrintServiceProductCategoryEntity,
  PrintServiceProductEntityHydrated,
  PrintServiceProductImageEntityHydrated,
  PrintServiceProductPriceEntity,
  TemplateTextColor,
  TemplateTextFont,
  TerritoryEntityHydrated,
} from "@jackfruit/common"
import {
  combineReducers,
  configureStore,
  DevToolsEnhancerOptions,
  EntityState,
} from "@reduxjs/toolkit"
import * as Sentry from "@sentry/browser"
import { navigate } from "gatsby-link"
import createSagaMiddleware from "redux-saga"
import { CartEntity } from "~/interfaces/entities/Cart"
import { GiftCertificateEntity } from "~/interfaces/entities/GiftCertificate"
import { ImageEntity } from "~/interfaces/entities/Image"
import { ImageRegionEntity } from "~/interfaces/entities/ImageRegion"
import { ImageTransformationEntity } from "~/interfaces/entities/ImageTransformation"
import { LineItemEntity } from "~/interfaces/entities/LineItem"
import { OrderEntity } from "~/interfaces/entities/Order"
import { OrderSummaryEntity } from "~/interfaces/entities/OrderSummary"
import { PageSessionEntity } from "~/interfaces/entities/PageSession"
import { ProductPageEntity } from "~/interfaces/entities/ProductPage"
import { StoreEntity } from "~/interfaces/entities/Store"
import { TextRegionEntity } from "~/interfaces/entities/TextRegion"
import { UploadEntity } from "~/interfaces/entities/Upload"
import { scrollTo } from "../services/Utils"
import { getCurrentPageId } from "./context"
import { actions } from "./process"
import rootSaga from "./saga/rootSaga"
import { application, ApplicationState } from "./state/application"
import { auth, AuthState } from "./state/auth"
import { blocks } from "./state/blocks"
import { blockTemplates } from "./state/blockTemplates"
import { cart, CartState } from "./state/cart"
import { carts } from "./state/carts"
import { categories } from "./state/categories"
import { clients } from "./state/client"
import { clientPrintServices } from "./state/clientPrintServices"
import { giftCertificates } from "./state/giftCertificates"
import { imageRegions } from "./state/imageRegions"
import { images } from "./state/images"
import { imageTransformations } from "./state/imageTransformations"
import { lineItems } from "./state/lineItems"
import { orders } from "./state/orders"
import { orderSummaries } from "./state/orderSummaries"
import { pages } from "./state/pages"
import { pageSessions } from "./state/pageSessions"
import { printServiceProductCategories } from "./state/printServiceProductCategory"
import { printServiceProductImages } from "./state/printServiceProductImages"
import { printServiceProductPrices } from "./state/printServiceProductPrices"
import { printServiceProducts } from "./state/printServiceProducts"
import { printServices } from "./state/printServices"
import { productPages } from "./state/productPages"
import {
  productTemplateCategories,
  productTemplates,
  productTemplateTags,
} from "./state/productTemplates"
import { settings, SettingsState } from "./state/settings"
import { stores } from "./state/stores"
import { templateTextColors } from "./state/template-text-colors"
import { templateTextFonts } from "./state/template-text-fonts"
import { territories } from "./state/territories"
import { textRegions } from "./state/textRegions"
import { uploads } from "./state/uploads"

export interface EnhancedEntityState<T> extends EntityState<T> {
  isLoading: boolean
  hasError: boolean
  error: string
}

export interface RootState {
  auth: AuthState
  settings: SettingsState
  application: ApplicationState
  pageSessions: EnhancedEntityState<PageSessionEntity>
  cart: CartState
  carts: EnhancedEntityState<CartEntity>
  orderSummaries: EnhancedEntityState<OrderSummaryEntity>
  orders: EnhancedEntityState<OrderEntity>
  pages: EnhancedEntityState<PageEntityHydrated>
  printServices: EnhancedEntityState<PrintServiceEntity>
  clients: EnhancedEntityState<ClientEntityHydrated>
  templateTextColors: EnhancedEntityState<TemplateTextColor>
  templateTextFonts: EnhancedEntityState<TemplateTextFont>
  clientPrintServices: EnhancedEntityState<ClientPrintServiceEntityHydrated>
  giftCertificates: EnhancedEntityState<GiftCertificateEntity>
  printServiceProducts: EnhancedEntityState<PrintServiceProductEntityHydrated>
  printServiceProductCategories: EnhancedEntityState<PrintServiceProductCategoryEntity>
  printServiceProductImages: EnhancedEntityState<PrintServiceProductImageEntityHydrated>
  printServiceProductPrices: EnhancedEntityState<PrintServiceProductPriceEntity>
  productTemplates: EnhancedEntityState<PrinticularProductTemplateEntity>
  productTemplateCategories: EnhancedEntityState<PrinticularProductTemplateCategoryEntity>
  productTemplateTags: EnhancedEntityState<PrinticularProductTemplateTagEntity>
  categories: EnhancedEntityState<PrinticularCategoryEntity>
  productPages: EnhancedEntityState<ProductPageEntity>
  imageRegions: EnhancedEntityState<ImageRegionEntity>
  textRegions: EnhancedEntityState<TextRegionEntity>
  uploads: EnhancedEntityState<UploadEntity>
  images: EnhancedEntityState<ImageEntity>
  imageTransformations: EnhancedEntityState<ImageTransformationEntity>
  lineItems: EnhancedEntityState<LineItemEntity>
  stores: EnhancedEntityState<StoreEntity>
  territories: EnhancedEntityState<TerritoryEntityHydrated>
  blockTemplates: EnhancedEntityState<BlockTemplateEntityHydrated>
  blocks: EnhancedEntityState<BlockEntityHydrated>
}

export const createStore = ({
  name,
  preloadedState,
}: { name?: string; preloadedState?: any } = {}) => {
  const rootReducer = combineReducers({
    auth: auth.reducer,
    settings: settings.reducer,
    application: application.reducer,
    pageSessions: pageSessions.reducer,
    cart: cart.reducer,
    carts: carts.reducer,
    orderSummaries: orderSummaries.reducer,
    orders: orders.reducer,
    pages: pages.reducer,
    printServices: printServices.reducer,
    clients: clients.reducer,
    templateTextColors: templateTextColors.reducer,
    templateTextFonts: templateTextFonts.reducer,
    clientPrintServices: clientPrintServices.reducer,
    printServiceProducts: printServiceProducts.reducer,
    printServiceProductImages: printServiceProductImages.reducer,
    printServiceProductPrices: printServiceProductPrices.reducer,
    printServiceProductCategories: printServiceProductCategories.reducer,
    productTemplates: productTemplates.reducer,
    productTemplateCategories: productTemplateCategories.reducer,
    productTemplateTags: productTemplateTags.reducer,
    categories: categories.reducer,
    productPages: productPages.reducer,
    imageRegions: imageRegions.reducer,
    textRegions: textRegions.reducer,
    uploads: uploads.reducer,
    images: images.reducer,
    imageTransformations: imageTransformations.reducer,
    lineItems: lineItems.reducer,
    stores: stores.reducer,
    territories: territories.reducer,
    blockTemplates: blockTemplates.reducer,
    blocks: blocks.reducer,
    giftCertificates: giftCertificates.reducer,
  })

  const reduxSagaMonitorOptions = {
    onError: (error: Error, { sagaStack }: { sagaStack: string }) => {
      console.log("### Saga error ###")
      console.error(error)
      console.log(sagaStack)
      Sentry.captureMessage(JSON.stringify(error))
      Sentry.captureException(error)
    },
    context: {
      getCurrentPageId,
      navigate,
      scrollTo,
    },
  }
  const sagaMiddleware = createSagaMiddleware(reduxSagaMonitorOptions)
  const middlewares = [sagaMiddleware]

  let devToolsOptions: boolean | DevToolsEnhancerOptions = false
  if (process.env.GATSBY_ACTIVE_ENV !== "production") {
    devToolsOptions = name ? { name } : true
  }

  const store = configureStore({
    devTools: devToolsOptions,
    reducer: rootReducer,
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware({
        immutableCheck: false,
        serializableCheck: {
          ignoredActions: [
            actions.uploadUriImages.type,
            actions.bootstrapPage.type,
            actions.queueImageForProcessing.type,
            actions.queueImageForUploading.type,
            actions.createUpload.type,
            actions.createUploadFromButton.type,
            actions.createLineItem.type,
            actions.createLineItemWithImage.type,
            actions.updateImageRegionImage.type,
            actions.updateMissingImagesForLineItem.type,
            actions.updateFulfillment.type,
            actions.createLineItemFromButton.type,
            actions.placeReOrder.type,
            actions.updateStore.type,
            actions.applyEditorChanges.type,
            actions.orderFromStore.type,
            actions.insertPageSpreadToLineItemAfter.type,
            actions.insertPageSpreadToLineItemBefore.type,
            actions.removePageSpreadFromLineItem.type,
            auth.actions.login.type,
            auth.actions.loginSuccess.type,
            auth.actions.logout.type,
          ],
        },
      }).concat(middlewares),
    preloadedState,
  })
  sagaMiddleware.run(rootSaga)

  return store
}
