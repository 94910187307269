import {
  Box,
  Button,
  Center,
  Flex,
  Icon,
  Image,
  Text,
  VStack,
} from "@chakra-ui/react"
import { AppEntityHydrated } from "@jackfruit/common"
import React from "react"
import { isAndroid, isMobileSafari, osVersion } from "react-device-detect"
import { useTranslation } from "react-i18next"
import { FiX } from "react-icons/fi"

interface Props {
  appConfig: AppEntityHydrated
  slideIn: boolean
  transitionTime: number
  onInstall: () => void
  onCloseBanner: () => void
}

const AppBannerTopSmart: React.FC<Props> = ({
  appConfig,
  onInstall,
  onCloseBanner,
  slideIn,
  transitionTime,
}) => {
  const { faviconImage, androidStoreName } = appConfig

  const { t } = useTranslation()

  /**
   *  Show smart banner if:
   *   User that  is using mobile safari and has ios version less than 6
   *  or  iOS user that don't use Safari
   *  or  Android user
   */
  const showSmartBanner =
    (isMobileSafari && Number(osVersion) < 6) || !isMobileSafari || isAndroid

  if (!showSmartBanner) {
    return null
  }

  return (
    <Center
      p={1}
      bg="#f6f6f6"
      id="install-mobile-app-banner"
      overflow="hidden"
      minH="88px"
    >
      <Flex
        justify="space-between"
        align="center"
        mt={slideIn ? 0 : "-88px"}
        transition={`${transitionTime}ms all`}
        width="full"
        py={1}
      >
        <Button
          size="xs"
          id="p-mobile-banner-dismiss-button" // GTM: click on mobile banner dismiss button
          bg="none"
          color="white"
          onClick={onCloseBanner}
          px={3}
          w={2}
        >
          <Icon as={FiX} boxSize={3} color="gray.400" />
        </Button>

        <Flex w="full">
          <Image
            className="p-mobile-banner-app-icon" // GTM: click on mobile banner app icon
            src={faviconImage.path}
            h="58px"
            w="58px"
            mr={2}
            onClick={onInstall}
            ignoreFallback={true}
          />
          <VStack align="flex-start" justify="center" spacing={0}>
            <Text>{androidStoreName}</Text>
            <Text fontSize="xs" color="gray.500">
              {t("components.AppBanner.Full.Description")}
            </Text>
          </VStack>
        </Flex>
        <Box pr={3}>
          <Box
            as="button"
            width="70px"
            h={7}
            fontWeight="bold"
            fontSize="sm"
            bg="#007aff"
            borderRadius="3xl"
            color="white"
            onClick={onInstall}
            className="btn btn-primary" // GTM: click on mobile banner store button
          >
            {t("components.AppBanner.Top.Install")}
          </Box>
        </Box>
      </Flex>
    </Center>
  )
}

export default AppBannerTopSmart
