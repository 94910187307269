import {
  Box,
  Button,
  Flex,
  Heading,
  Icon,
  Image,
  Link,
  useDisclosure,
} from "@chakra-ui/react"
import {
  AppEntityHydrated,
  BlockHeaderEntity,
  PageEntityHydrated,
} from "@jackfruit/common"
import React, { useMemo } from "react"
import { FiMenu } from "react-icons/fi"
import { useBlock } from "~/hooks/useBlock"
import { usePage } from "~/hooks/usePage"
import AppBannerWrapper from "../app-banner/AppBannerWrapper"
import LocaleNavigation from "../fulfillment/LocaleUrls/LocaleNavigation"
import NavigationMobile from "../navigation/NavigationMobile"
import Wrapper from "../Wrapper"

interface Props {
  config: BlockHeaderEntity
  pages: PageEntityHydrated[]
  appConfig: AppEntityHydrated
}

const HeaderMobile: React.FC<Props> = ({ config, pages, appConfig }) => {
  const { displayName, baseUrl, logoImage, additionalLocaleUrls } = appConfig
  const {
    mobileNavigationId,
    mobileNavigationBackgroundColor,
    mobileNavigationColor,
    blockId,
  } = config

  const { mobileNavigation } = useMemo(() => {
    const mobileNavigation = appConfig.navigations.find(
      nav => Number(nav.id) === Number(mobileNavigationId)
    )

    return { mobileNavigation }
  }, [appConfig.navigations, mobileNavigationId])

  const { block } = useBlock(blockId)
  const { page } = usePage(block?.pageId)

  const { pathname: basePathname } = new URL(baseUrl)

  const hideMobileAppInstallBanner = Boolean(page)
    ? page.hideMobileAppInstallBanner
    : false

  const { isOpen, onToggle } = useDisclosure()

  if (!mobileNavigation) {
    return null
  }

  const logoComponent =
    logoImage && logoImage.path ? (
      <Image
        id="p-header-brand-logo-mobile"
        width="auto"
        height="auto"
        htmlHeight={40}
        htmlWidth={114}
        maxH="3.25rem"
        ignoreFallback={true}
        src={logoImage.path}
        alt={`${displayName} Logo`}
        title={`${displayName} Logo`}
      />
    ) : (
      <Heading as="h1" fontSize="xl">
        Autopilot Print
      </Heading>
    )

  return (
    <Box>
      {!hideMobileAppInstallBanner && (
        <AppBannerWrapper appConfig={appConfig} />
      )}
      <Wrapper
        bg="white"
        position="relative"
        zIndex={10}
        boxShadow="base"
        minHeight="60px"
      >
        <Flex justifyContent="space-between" alignItems="center" py={4}>
          <Link
            id="p-header-logo-link-mobile"
            maxW={44}
            href={basePathname ?? "/"}
          >
            {logoComponent}
          </Link>
          <LocaleNavigation localeUrls={additionalLocaleUrls} />
          <Button
            id="mobile-navigation-toggle"
            variant="link"
            borderRadius={0}
            onClick={onToggle}
            px={1}
            py={2}
          >
            <Icon as={FiMenu} boxSize="1.5em" />
          </Button>
        </Flex>
      </Wrapper>

      {isOpen && (
        <Wrapper
          bg={mobileNavigationBackgroundColor}
          zIndex={10}
          borderTopColor="gray.50"
          borderTopWidth={1}
          boxShadow="base"
          position="relative"
        >
          <NavigationMobile
            justify="flex-start"
            color={mobileNavigationColor}
            navigation={mobileNavigation}
            pages={pages}
            py={4}
            spacing={8}
          />
        </Wrapper>
      )}
    </Box>
  )
}

export default HeaderMobile
