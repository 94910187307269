import { useCallback } from "react"
import { useDispatch } from "react-redux"
import { useEffectOnce, useLocation } from "react-use"
import { actions } from "~/redux/process"
import { application } from "~/redux/state/application"
import { auth } from "~/redux/state/auth"
import { settings } from "~/redux/state/settings"
import {
  generateDeviceToken,
  getLocalStorageItem,
  getValidUrlParams,
} from "~/services/Utils"
import { useSettings } from "./useSettings"

export const useBootstrapApplication = () => {
  const dispatch = useDispatch()
  const {
    settings: { isUserAccountEnabled, loggingMode },
  } = useSettings()

  const { search } = useLocation()

  const boot = useCallback(() => {
    if (window) {
      window.debug = loggingMode !== "disabled"
    }

    // restore checkout data from local storage if available
    dispatch(actions.restoreCheckoutData())
    dispatch(actions.restoreCartData())

    // Generate device token
    const deviceToken = getLocalStorageItem(
      "deviceToken",
      generateDeviceToken()
    )

    // get all params
    const { referralCode, referralPartner } = getValidUrlParams(
      ["referralCode", "referralPartner"],
      search
    )

    // Register settings
    dispatch(settings.actions.updateDeviceToken(deviceToken))

    // Register URL params
    dispatch(
      application.actions.setUrlParams({
        ...(referralCode && { referralCode }),
        ...(referralPartner && { referralPartner }),
      })
    )

    if (isUserAccountEnabled) {
      // Restore user session if possible
      dispatch(auth.actions.loginSilently())
    }
  }, [dispatch, isUserAccountEnabled, loggingMode, search])

  useEffectOnce(boot)
}
